import Trix from 'trix';
Trix.config.textAttributes.underline = { tagName: 'u', inheritable: false };

type TrixElement = HTMLElement & {
  editor: any;
  toolbarElement: HTMLElement;
};

addEventListener('trix-initialize', function (event) {
  const element = event.target as TrixElement;
  const editor = element.editor;
  const toolbarElement = element.toolbarElement;
  const groupElement = toolbarElement.querySelector('.trix-button-group.trix-button-group--text-tools');

  groupElement.insertAdjacentHTML(
    'beforeend',
    '<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline"><sub>Underline</sub></button>',
  );

  const selectedAttributes = new Set();
  function updateSelectedAttributes() {
    selectedAttributes.clear();

    const selectedRange = editor.getSelectedRange();
    if (selectedRange[0] === selectedRange[1]) selectedRange[1]++;

    const selectedPieces: any[] = editor.getDocument().getDocumentAtRange(selectedRange).getPieces();
    selectedPieces.forEach((piece) => {
      Object.keys(piece.getAttributes()).forEach((attribute) => {
        selectedAttributes.add(attribute);
      });
    });
  }

  updateSelectedAttributes();
  element.addEventListener('trix-selection-change', updateSelectedAttributes);

  if (element.dataset.content) editor.insertHTML(element.dataset.content);
});
