import { CnOverlayPanel } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';
import { loadCartContent } from '../../../cart_loader';

export default class extends Controller<CnOverlayPanel> {
  public connect() {
    this.handleOpenCartParam();
  }

  private handleOpenCartParam() {
    const params = new URLSearchParams(window.location.search);
    if (!params.has('open_cart')) return;

    loadCartContent();
    this.element.open();
    params.delete('open_cart');
    history.replaceState(history.state, '', `${location.pathname}?${params}`);
  }
}
