import { Controller } from '@hotwired/stimulus';

export default class extends Controller<HTMLInputElement> {
  public static values = { selector: String };

  public declare selectorValue: string;
  public declare readonly hasSelectorValue: boolean;

  public connect() {
    this.element.addEventListener('change', () => {
      document.querySelectorAll(this.selectorValue).forEach((visibilityTarget) => {
        if (this.element.checked) {
          visibilityTarget.classList.remove('hide');
        } else {
          visibilityTarget.classList.add('hide');
        }
      });
    });
  }
}
