import { CnCheckbox, CnRadioButton, CnSelect } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['addressSwitch', 'saveAddressSwitch', 'saveAddressPrivate'];

  public declare readonly addressSwitchTargets: CnRadioButton[];
  public declare readonly saveAddressSwitchTargets: CnRadioButton[];
  public declare readonly saveAddressPrivateTarget: CnCheckbox;

  public connect() {
    this.switchAddressSelector();
  }

  public switchAddressSelector() {
    document
      .querySelectorAll<HTMLInputElement | CnSelect>('input[data-address-tile], cn-select[data-address-tile]')
      .forEach((x) => (x.disabled = true));

    const option = this.addressSwitchTargets.find((o) => o.checked);
    if (!option) return;

    this.saveAddressSwitchTargets.forEach((input) => (input.disabled = option.value !== 'custom'));
    this.saveAddressPrivateTarget.disabled = option.value !== 'custom';
    document
      .querySelectorAll<
        HTMLInputElement | CnSelect
      >(`.${option.id}-address input[data-address-tile], .${option.id}-address cn-select[data-address-tile]`)
      .forEach((x) => (x.disabled = false));
  }

  public switchSaveAddress() {
    const saveSelected = this.saveAddressSwitchTargets.find((x) => (x.value = 'yes')).checked;
    this.saveAddressPrivateTarget.classList.toggle('d-none', !saveSelected);
  }
}
