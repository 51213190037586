import { CnCheckbox } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller<CnCheckbox> {
  public static values = { logo: String };

  public declare readonly logoValue: string;

  private companyImage: HTMLImageElement;

  private get defaultImages(): HTMLImageElement[] {
    return [
      document.querySelector('cn-sidenav .hencke-logo'),
      document.querySelector('cn-sidenav .creative-network-logo'),
    ];
  }

  public connect() {
    this.companyImage = this.createCompanyImage();

    this.setImages();
    this.element.addEventListener('change', () => {
      this.setImages();
    });
  }

  private setImages() {
    if (this.element.checked) {
      this.hideDefaultImages();
      this.showCompanyImage();
    } else {
      this.showDefaultImages();
      this.hideCompanyImage();
    }
  }

  private hideDefaultImages() {
    this.defaultImages.forEach((image) => {
      image.classList.add('d-none');
    });
  }

  private showDefaultImages() {
    this.defaultImages.forEach((image) => {
      image.classList.remove('d-none');
    });
  }

  private hideCompanyImage() {
    this.companyImage.classList.add('d-none');
  }

  private showCompanyImage() {
    this.companyImage.classList.remove('d-none');
  }

  private createCompanyImage() {
    const image = document.createElement('img');
    image.src = this.logoValue;
    image.classList.add('customer-individual-logo', 'd-none');
    image.slot = 'logos';

    document.querySelector('cn-sidenav').appendChild(image);

    return image;
  }
}
