import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['destroy'];
  public static values = { itemId: String };

  public declare readonly destroyTarget: HTMLInputElement;

  public declare itemIdValue: string;

  public markForDestruction(event: PointerEvent) {
    event.preventDefault();
    $(this.destroyTarget).val('1');

    document.querySelectorAll(`[id*="${this.itemIdValue}"]`).forEach((element) => {
      element.classList.add('d-none');
    });
  }
}
