import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static values = { url: String, delay: Number };

  public declare readonly urlValue: string;
  public declare readonly delayValue: number;

  public declare readonly hasUrlValue: boolean;
  public declare readonly hasDelayValue: boolean;

  public async connect() {
    if (this.hasDelayValue) {
      await new Promise((resolve) => setTimeout(resolve, this.delayValue));
    }

    Turbo.visit(this.urlValue);
  }
}
