import { Controller } from '@hotwired/stimulus';

export default class extends Controller<HTMLFormElement> {
  public static targets = ['hardware', 'subsidized', 'dialog'];

  public declare readonly hardwareTarget: HTMLInputElement;
  public declare readonly subsidizedTarget: HTMLInputElement;
  public declare readonly dialogTarget: HTMLDialogElement;

  public trySubmit() {
    if (this.anyDiscount()) {
      this.submit();
    } else {
      this.openModal();
    }
  }

  public submit() {
    this.element.submit();
  }

  public openModal() {
    this.dialogTarget.showModal();
  }

  public closeModal() {
    this.dialogTarget.close();
  }

  private anyDiscount() {
    const hardwareDiscounted =
      this.hardwareTarget.value !== '' && this.hardwareTarget.value !== undefined && this.hardwareTarget.value !== '0';
    const subsidizedDiscounted =
      this.subsidizedTarget.value !== '' &&
      this.subsidizedTarget.value !== undefined &&
      this.subsidizedTarget.value !== '0';

    return hardwareDiscounted || subsidizedDiscounted;
  }
}
