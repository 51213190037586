import { Controller } from '@hotwired/stimulus';
import { OsmocoderResponse } from '../interfaces/osmocoder_response.interface';

export default class extends Controller {
  public connect() {
    const _this = this;
    $('.osmocoder-address #zip_code').autocomplete({
      minLength: 3,
      delay: 500,
      source: (request, response) => {
        const osmocoderSelector = _this.getSelector();
        const zipcode = $(`${osmocoderSelector} #zip_code`).val();
        const country = $(`${osmocoderSelector} .address-country-dropdown input`).val();

        $.get(`/osmocoder_addresses/?country=${country}&zipcode=${zipcode}`, (data: { data: OsmocoderResponse[] }) => {
          response(
            data.data
              .map((e) => `${e.zipcode}\u2009${e.place}`)
              .filter((value, index, self) => self.indexOf(value) === index),
          );
        });
      },
      select: _this.cityAndZipSelect.bind(_this),
    });

    $('.osmocoder-address #city').autocomplete({
      minLength: 3,
      delay: 500,
      source: (request, response) => {
        const osmocoderSelector = _this.getSelector();
        const city = $(`${osmocoderSelector} #city`).val();
        const country = $(`${osmocoderSelector} .address-country-dropdown input`).val();

        $.get(`/osmocoder_addresses/?country=${country}&place=${city}`, (data: { data: OsmocoderResponse[] }) => {
          response(
            data.data
              .map((e) => `${e.zipcode}\u2009${e.place}`)
              .filter((value, index, self) => self.indexOf(value) === index),
          );
        });
      },
      select: _this.cityAndZipSelect.bind(_this),
    });

    $('.osmocoder-address #street').autocomplete({
      minLength: 3,
      delay: 500,
      source: (request, response) => {
        const osmocoderSelector = _this.getSelector();
        const street = $(`${osmocoderSelector} #street`).val();
        const zipcode = $(`${osmocoderSelector} #zip_code`).val();
        const country = $(`${osmocoderSelector} .address-country-dropdown input`).val();

        $.get(
          `/osmocoder_addresses/?country=${country}&street=${street}&zipcode=${zipcode}`,
          (data: { data: OsmocoderResponse[] }) => {
            response(
              data.data
                .map((e) => `${e.street}\u2009-\u2009${e.zipcode}\u2009${e.place}`)
                .filter((value, index, self) => self.indexOf(value) === index),
            );
          },
        );
      },
      select: (event, ui) => {
        const itemValue = ui.item.value.split('\u2009');

        setTimeout(() => {
          const osmocoderSelector = _this.getSelector();
          $(`${osmocoderSelector}.osmocoder-address #street`).val(itemValue[0]);
          $(`${osmocoderSelector}.osmocoder-address #zip_code`).val(itemValue[2]);
          $(`${osmocoderSelector}.osmocoder-address #city`).val(itemValue[3]);
        }, 1);
      },
    });
  }

  private cityAndZipSelect(event, ui) {
    const itemValue = ui.item.value.split('\u2009');
    const osmocoderSelector = this.getSelector();

    setTimeout(() => {
      $(`${osmocoderSelector}.osmocoder-address #zip_code`).val(itemValue[0]);
      $(`${osmocoderSelector}.osmocoder-address #zip`).val(itemValue[0]);
      $(`${osmocoderSelector}.osmocoder-address #city`).val(itemValue[1]);
    }, 1);
  }

  private getSelector() {
    let osmocoderSelector = '.osmocoder-address';
    if ($('#newAddressModal').hasClass('show')) {
      osmocoderSelector = '#newAddressModal .osmocoder-address';
    }
    return osmocoderSelector;
  }
}
