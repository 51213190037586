import { BrowserJsPlumbInstance, newInstance } from '@jsplumb/browser-ui';
import { Controller } from '@hotwired/stimulus';
import { FlowchartConnector } from '@jsplumb/connector-flowchart';
import { debounce } from '../../../debounce';

export default class extends Controller<HTMLDivElement> {
  public declare readonly simAndDeviceHeadlineTarget: HTMLElement;
  public declare readonly simAndDeviceContentTargets: HTMLElement[];
  public declare readonly simHeadlineTarget: HTMLElement;
  public declare readonly simContentTargets: HTMLElement[];
  public declare readonly hasSimHeadlineTarget: boolean;

  private jsPlumbInstance: BrowserJsPlumbInstance;
  private plumbedElements: Element[] = [];

  public connect() {
    this.jsPlumbInstance = newInstance({ container: this.element, elementsDraggable: false, resizeObserver: true });
    this.watchSidenav();
    window.addEventListener('resize', () => {
      debounce(() => this.revalidate(), 500)();
    });

    setTimeout(() => {
      this.connectMsisdnsAndSims();
      this.connectSimsAndMobiles();
      this.checkWhichWayToConnect();
      this.connectMsisndsAndMobiles();
    }, 100);
  }

  private watchSidenav() {
    document.querySelector('cn-sidenav').addEventListener('toggling', () => {
      // Wait for sidenav animation
      setTimeout(() => {
        this.revalidate();
      }, 500);
    });
  }

  private connectMsisdnsAndSims() {
    const msisdns = this.element.querySelectorAll('.msisdn');
    msisdns.forEach((msisdn) => this.connectMsisdnAndSims(msisdn as HTMLElement));
  }

  private connectMsisdnAndSims(msisdn: HTMLElement) {
    const msisdnName = msisdn.getAttribute('data-msisdn-name');
    const sims = this.element.querySelectorAll(`.sim-card[data-msisdn-name="${msisdnName}"] > .sim-card-icon`);

    sims.forEach((sim) => {
      this.connectElements(msisdn, sim);
    });
  }

  private connectSimsAndMobiles() {
    const sims = this.element.querySelectorAll('.sim-card');
    sims.forEach((sim) => this.connectSimAndMobilesViaSimName(sim as HTMLElement));
    sims.forEach((sim) => this.connectSimAndMobilesViaMobileId(sim as HTMLElement));
  }

  private connectSimAndMobilesViaSimName(sim: HTMLElement) {
    const simName = sim.getAttribute('data-sim-name');
    const mobiles = this.element.querySelectorAll(`.mobile[data-sim-name="${simName}"]`);

    mobiles.forEach((mobile) => {
      this.connectElements(sim.querySelector('.sim-card-name'), mobile);
    });
  }

  private connectSimAndMobilesViaMobileId(sim: HTMLElement) {
    const mobileIds: string[] = JSON.parse(sim.getAttribute('data-mobile-ids'));
    const mobiles: HTMLElement[] = [];
    mobileIds.forEach((mobileId) => {
      mobiles.push(...this.element.querySelectorAll<HTMLElement>(`.mobile[data-mobile-id="${mobileId}"]`));
    });

    mobiles.forEach((mobile) => {
      this.connectElements(sim.querySelector('.sim-card-name'), mobile);
    });
  }

  private checkWhichWayToConnect() {
    const mobiles = this.element.querySelectorAll('.mobile');
    mobiles.forEach((mobile) => {
      const mobileId = mobile.getAttribute('data-mobile-id');
      const importTypes = this.element.querySelectorAll<HTMLElement>(
        `.mobile-import-type[data-mobile-id="${mobileId}"]`,
      );

      if (importTypes.length === 0) {
        this.connectMobileAndImei(mobile as HTMLElement);
      } else {
        this.connectMobileAndImportType(mobile as HTMLElement);
      }
    });
  }

  private connectMobileAndImportType(mobile: HTMLElement) {
    const mobileId = mobile.getAttribute('data-mobile-id');
    const importTypes = this.element.querySelectorAll<HTMLElement>(`.mobile-import-type[data-mobile-id="${mobileId}"]`);

    importTypes.forEach((importType) => {
      this.connectElements(mobile, importType);
      this.connectImportTypeAndImei(importType, mobile);
    });
  }

  private connectImportTypeAndImei(importType: HTMLElement, mobile: HTMLElement) {
    const mobileId = mobile.getAttribute('data-mobile-id');
    const imeis = this.element.querySelectorAll<HTMLElement>(`.imei[data-mobile-id="${mobileId}"]`);

    imeis.forEach((imei) => {
      this.connectElements(importType, imei);
      this.connectImeiAndImei2(imei);
    });
  }

  private connectMobileAndImei(mobile: HTMLElement) {
    const mobileId = mobile.getAttribute('data-mobile-id');
    const imeis = this.element.querySelectorAll<HTMLElement>(`.imei[data-mobile-id="${mobileId}"]`);

    imeis.forEach((imei) => {
      this.connectElements(mobile, imei);
      this.connectImeiAndImei2(imei);
    });
  }

  private connectImeiAndImei2(imei: HTMLElement) {
    const imeiName = imei.textContent;
    const imei2s = this.element.querySelectorAll(`.imei2[data-imei-name="${imeiName}"]`);

    imei2s.forEach((imei2) => {
      this.connectElements(imei, imei2);
    });
  }

  private connectMsisndsAndMobiles() {
    const msisdns = this.element.querySelectorAll('.msisdn');
    msisdns.forEach((msisdn) => this.connectMsisdnAndMobiles(msisdn as HTMLElement));
  }

  private connectMsisdnAndMobiles(msisdn: HTMLElement) {
    const msisdnName = msisdn.getAttribute('data-msisdn-name');
    const mobiles = this.element.querySelectorAll(`.mobile[data-msisdn-name="${msisdnName}"]:not([data-sim-name])`);

    mobiles.forEach((mobile) => {
      this.connectElements(msisdn, mobile);
    });
  }

  private connectElements(source: Element, target: Element) {
    this.plumbedElements.push(source, target);
    this.jsPlumbInstance.connect({
      source,
      target,
      anchors: ['Right', 'Left'],
      endpoint: 'Blank',
      color: 'var(--thin-border-color)',
      connector: {
        type: FlowchartConnector.type,
        options: {
          cornerRadius: 6,
          gap: 8,
        },
      },
    });
  }

  private revalidate() {
    this.plumbedElements.forEach((element) => {
      this.jsPlumbInstance.revalidate(element);
    });
  }
}
