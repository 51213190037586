import { CnStep, CnStepper } from '@creative-network/hencke-layout';
import { StreamActions, StreamElement } from '@hotwired/turbo';

StreamActions.stepper = function (this: StreamElement) {
  const isNext = this.getAttribute('direction') === 'next';
  const isPrevious = this.getAttribute('direction') === 'previous';
  const stepperSelector = this.getAttribute('selector');

  const stepper = document.querySelector<CnStepper>(stepperSelector);

  if (isNext) stepper.next();
  if (isPrevious) stepper.previous();
};

StreamActions.completeStep = function (this: StreamElement) {
  const stepSelector = this.getAttribute('selector');

  const step = document.querySelector<CnStep>(stepSelector);
  if (!step) return;

  step.completed = true;
};
