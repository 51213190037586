import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['input'];

  public declare readonly inputTarget: HTMLInputElement;

  public copy() {
    const inputValue = this.inputTarget.value;
    navigator.clipboard.writeText(inputValue);
  }
}
