import { StreamActions, StreamElement } from '@hotwired/turbo';

StreamActions.snackbar = function (this: StreamElement) {
  const isSuccess = this.getAttribute('type') === 'success';
  const message = this.getAttribute('message');
  const buttonText = this.getAttribute('button_text');
  const snackBar = window.getSnackbar();

  if (isSuccess) snackBar.success(message, buttonText);
  if (!isSuccess) snackBar.error(message, buttonText);
};
