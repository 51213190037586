import { CnSelect } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';

interface TradeIn {
  device: {
    name: string;
    type: string;
  };
}

interface PositionsGroup {
  identifier: string;
  leaseTerm: string;
  tariffId: string;
  default: boolean;
  hasDevicePosition: boolean;
}

export default class extends Controller {
  public static targets = ['input', 'progress', 'hiddenValue', 'container', 'positionsGroupSelect'];
  public static values = { recipientId: String, recipientPersonId: String };

  public declare readonly inputTarget: HTMLInputElement;
  public declare readonly progressTarget: HTMLDivElement;
  public declare readonly hiddenValueTarget: HTMLInputElement;
  public declare readonly containerTarget: HTMLDivElement;
  public declare readonly positionsGroupSelectTarget: CnSelect;

  public declare readonly hasHiddenValueTarget: boolean;

  public declare recipientIdValue: string;
  public declare recipientPersonIdValue: string;

  public connect() {
    this.init();
    const _this = this;

    $(this.inputTarget)
      .autocomplete({
        source: (request, response) => {
          const params = new URLSearchParams();
          params.set('except', _this.selectedTradeInIDsForRecipient().join(','));
          params.set('term', this.inputTarget.value);

          fetch(`/bulk_orders/recipients/${_this.recipientIdValue}/trade_ins?${params}`)
            .then((res) => res.json())
            .then((data: { data }) => {
              response(data);
            });
        },
        autoFocus: true,
        minLength: 0,
        response: function () {
          _this.progressTarget.classList.add('hide');
        },
        search: function () {
          _this.progressTarget.classList.add('hide');
        },
        focus: function (event) {
          event.preventDefault();
        },
        select: function (event, ui) {
          if (_this.hasHiddenValueTarget) {
            event.preventDefault();

            const label = ui.item.label || ui.item.value;
            const value = ui.item.value || ui.item.label;

            _this.inputTarget.value = label;
            _this.hiddenValueTarget.value = value;

            _this.updateVisibility(!!_this.hiddenValueTarget.value);
          }
        },
      })
      .on('focus', function () {
        $(this).data('uiAutocomplete').search();
      });
  }

  public remove() {
    this.inputTarget.value = '';
    this.hiddenValueTarget.value = '';

    this.updateVisibility(false);
  }

  private init() {
    if (!!this.hiddenValueTarget.value) {
      this.progressTarget.classList.remove('hide');

      fetch(`/bulk_orders/recipients/${this.recipientIdValue}/trade_ins/${this.hiddenValueTarget.value}`)
        .then((res) => res.json())
        .then((res: TradeIn) => {
          this.inputTarget.value = res.device.name;
          this.progressTarget.classList.add('hide');
        });
    }

    this.positionsGroupSelectTarget.addEventListener('change', this.positionsGroupChanged.bind(this));

    this.updateVisibility(!!this.hiddenValueTarget.value);
    this.positionsGroupChanged();
  }

  private positionsGroupChanged() {
    const selectedPositionsGroupID = this.positionsGroupSelectTarget.value[0];

    fetch(`/bulk_orders/positions_groups/${selectedPositionsGroupID}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res: PositionsGroup) => {
        this.containerTarget.classList.toggle('hide', !res.hasDevicePosition);
      });
  }

  private updateVisibility(selected: boolean) {
    this.containerTarget.querySelector('.trade-in-selected').classList.toggle('hide', !selected);
  }

  private selectedTradeInIDsForRecipient(): string[] {
    const selectedTradeInIDs = [];

    document
      .querySelectorAll<HTMLInputElement>(
        `input[name$="[trade_in_id]"][data-recipient-person-id="${this.recipientPersonIdValue}"]`,
      )
      .forEach((input) => {
        if (input.value) selectedTradeInIDs.push(input.value);
      });

    return selectedTradeInIDs;
  }
}
