import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['modal', 'step', 'closeButton', 'nextButton', 'previousButton', 'finishButton'];

  public declare readonly modalTarget: HTMLDivElement;
  public declare readonly stepTargets: HTMLDivElement[];
  public declare readonly closeButtonTarget: HTMLButtonElement;
  public declare readonly nextButtonTarget: HTMLButtonElement;
  public declare readonly previousButtonTarget: HTMLButtonElement;
  public declare readonly finishButtonTarget: HTMLButtonElement;

  private stepIndex = 0;

  public connect() {
    this.closeButtonTarget.addEventListener('click', this.closeModal.bind(this));
    this.nextButtonTarget.addEventListener('click', this.nextStep.bind(this));
    this.previousButtonTarget.addEventListener('click', this.previousStep.bind(this));
    this.finishButtonTarget.addEventListener('click', this.closeModal.bind(this));

    this.toggleSteps();
  }

  public nextStep() {
    this.stepIndex = this.clamp(this.stepIndex + 1, 0, this.stepTargets.length - 1);
    this.toggleSteps();
  }

  public previousStep() {
    this.stepIndex = this.clamp(this.stepIndex - 1, 0, this.stepTargets.length - 1);
    this.toggleSteps();
  }

  public closeModal() {
    this.stepIndex = 0;
    $(this.modalTarget).modal('hide');
    setTimeout(() => this.toggleSteps(), 2000);
  }

  private toggleSteps() {
    for (let i = 0; i < this.stepTargets.length; i++) {
      if (i === this.stepIndex) {
        this.stepTargets[i].classList.remove('d-none');
      } else {
        this.stepTargets[i].classList.add('d-none');
      }
    }

    if (this.stepIndex === 0) {
      this.nextButtonTarget.classList.remove('d-none');
      this.finishButtonTarget.classList.add('d-none');
      this.closeButtonTarget.classList.remove('d-none');
      this.previousButtonTarget.classList.add('d-none');
    } else if (this.stepIndex === this.stepTargets.length - 1) {
      this.nextButtonTarget.classList.add('d-none');
      this.finishButtonTarget.classList.remove('d-none');
      this.closeButtonTarget.classList.add('d-none');
      this.previousButtonTarget.classList.remove('d-none');
    } else if (this.stepIndex > 0) {
      this.nextButtonTarget.classList.remove('d-none');
      this.finishButtonTarget.classList.add('d-none');
      this.closeButtonTarget.classList.add('d-none');
      this.previousButtonTarget.classList.remove('d-none');
    }
  }

  private clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
}
