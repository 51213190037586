import { Controller } from '@hotwired/stimulus';
import { OsmocoderResponse } from '../interfaces/osmocoder_response.interface';

export default class extends Controller {
  public static targets = ['zipCode', 'city', 'street', 'country'];

  public declare readonly zipCodeTarget?: HTMLInputElement;
  public declare readonly cityTarget?: HTMLInputElement;
  public declare readonly streetTarget?: HTMLInputElement;
  public declare readonly countryTarget?: HTMLInputElement;

  public declare readonly hasZipCodeTarget: boolean;
  public declare readonly hasCityTarget: boolean;
  public declare readonly hasStreetTarget: boolean;
  public declare readonly hasCountryTarget: boolean;

  public connect() {
    this.zipCodeAutocomplete();
    this.cityAutocomplete();
    this.streetAutocomplete();
  }

  private zipCodeAutocomplete() {
    if (!this.hasZipCodeTarget) return;

    $(this.zipCodeTarget).autocomplete({
      minLength: 3,
      delay: 500,
      source: (request, response) => {
        const zipcode = this.zipCodeTarget?.value;
        const country = this.countryTarget?.value;
        $.get(`/osmocoder_addresses/?country=${country}&zipcode=${zipcode}`, (data: { data: OsmocoderResponse[] }) => {
          response(
            data.data
              .map((e) => `${e.zipcode}\u2009${e.place}`)
              .filter((value, index, self) => self.indexOf(value) === index),
          );
        });
      },
      select: (event, ui) => {
        const itemValue = ui.item.value.split('\u2009');

        setTimeout(() => {
          if (this.hasZipCodeTarget) this.zipCodeTarget.value = itemValue[0];
          if (this.hasCityTarget) this.cityTarget.value = itemValue[1];
        }, 1);
      },
    });
  }

  private cityAutocomplete() {
    if (!this.hasCityTarget) return;

    $(this.cityTarget).autocomplete({
      minLength: 3,
      delay: 500,
      source: (request, response) => {
        const city = this.cityTarget?.value;
        const country = this.countryTarget?.value;
        $.get(`/osmocoder_addresses/?country=${country}&place=${city}`, (data: { data: OsmocoderResponse[] }) => {
          response(
            data.data
              .map((e) => `${e.zipcode}\u2009${e.place}`)
              .filter((value, index, self) => self.indexOf(value) === index),
          );
        });
      },
      select: (event, ui) => {
        const itemValue = ui.item.value.split('\u2009');

        setTimeout(() => {
          if (this.hasZipCodeTarget) this.zipCodeTarget.value = itemValue[0];
          if (this.hasCityTarget) this.cityTarget.value = itemValue[1];
        }, 1);
      },
    });
  }

  private streetAutocomplete() {
    if (!this.hasStreetTarget) return;

    $(this.streetTarget).autocomplete({
      minLength: 3,
      delay: 500,
      source: (request, response) => {
        const street = this.streetTarget?.value;
        const zipcode = this.zipCodeTarget?.value;
        const country = this.countryTarget?.value;
        $.get(
          `/osmocoder_addresses/?country=${country}&street=${street}&zipcode=${zipcode}`,
          (data: { data: OsmocoderResponse[] }) => {
            response(
              data.data
                .map((e) => `${e.street}\u2009-\u2009${e.zipcode}\u2009${e.place}`)
                .filter((value, index, self) => self.indexOf(value) === index),
            );
          },
        );
      },
      select: (event, ui) => {
        const itemValue = ui.item.value.split('\u2009');

        setTimeout(() => {
          if (this.hasStreetTarget) this.streetTarget.value = itemValue[0];
          if (this.hasZipCodeTarget) this.zipCodeTarget.value = itemValue[2];
          if (this.hasCityTarget) this.cityTarget.value = itemValue[3];
        }, 1);
      },
    });
  }
}
