import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static values = { notice: String, alert: String };

  public declare readonly noticeValue: string;
  public declare readonly alertValue: string;
  public declare readonly hasNoticeValue: boolean;
  public declare readonly hasAlertValue: boolean;

  public connect() {
    if (this.hasNoticeValue) {
      document.querySelector('cn-snackbar-container').success(this.noticeValue, 'Ok', 5000);
    }

    if (this.hasAlertValue) {
      document.querySelector('cn-snackbar-container').error(this.alertValue, 'Ok', 5000);
    }
  }
}
