import { Controller } from '@hotwired/stimulus';
import { escapeRegex } from '../../regex';

export default class extends Controller {
  public search(event: InputEvent) {
    const eventTarget = $(event.target);
    $('.recipients')
      .children()
      .each(function () {
        const option = $(this);
        const regex = new RegExp(`.*${escapeRegex(eventTarget.val().toString().toLowerCase())}.*`);

        if (!regex.test(option.find('.recipient-name').text().toLowerCase())) {
          option.css('display', 'none');
        } else {
          option.css('display', 'block');
        }
      });
  }
}
