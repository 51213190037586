import Chart from 'chart.js/auto';
import { ChartData } from '../../interfaces/chart_data.interface';
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { isDarkMode } from '../../is_dark_mode';
import { stringToColor } from '../../string_to_color';

export class BarController extends Controller {
  public static targets = ['canvas'];
  public static values = { data: Array };

  public declare readonly canvasTarget: HTMLCanvasElement;
  public declare readonly dataValue: ChartData[];

  private chart: undefined | Chart<'bar'>;

  public connect() {
    this.setupChart();
    this.listenSidenavToggling();
    this.listenResize();
  }

  private setupChart() {
    Chart.defaults.color = isDarkMode ? '#fff' : Chart.defaults.color.toString();
    this.chart = new Chart(this.canvasTarget, {
      type: 'bar',
      data: {
        labels: this.dataValue.map((data) => data.text),
        datasets: [
          {
            backgroundColor: this.dataValue.map((data) => stringToColor(data.text)),
            data: this.dataValue.map((data) => data.value),
            borderWidth: 1,
            borderColor: 'black',
          },
        ],
      },
      options: {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            color: '#fff',
          },
        },
        onHover: (event, elements) => {
          const eventTarget = event.native.target as HTMLCanvasElement;
          eventTarget.style.cursor = elements[0] ? 'pointer' : 'default';
        },
        onClick: (_, elements) => {
          const data = this.dataValue[elements[0]?.index];
          if (!data) return;

          Turbo.visit(data.link);
        },
      },
    });
  }

  private listenResize() {
    window.addEventListener('resize', () => {
      if (!this.chart) return;

      this.chart.destroy();
      this.setupChart();
    });
  }

  private listenSidenavToggling() {
    document.querySelector('cn-sidenav')?.addEventListener('toggling', () => {
      if (!this.chart) return;

      this.chart.destroy();
      // Wait for sidenav animation
      setTimeout(() => {
        this.setupChart();
      }, 500);
    });
  }
}
