import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['origin', 'condition', 'target'];

  public declare readonly originTarget: HTMLInputElement;
  public declare readonly conditionTarget: HTMLInputElement;
  public declare readonly targetTarget: HTMLInputElement;
  public declare readonly hasConditionTarget: boolean;

  public copy() {
    const inputValue = this.originTarget.value;

    if (this.hasConditionTarget && this.conditionTarget.value === '') {
      this.targetTarget.value = '';
    } else {
      this.targetTarget.value = inputValue;
    }
  }
}
