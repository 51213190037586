import { Controller } from '@hotwired/stimulus';
import { debounce } from '../debounce';
import { post } from '@rails/request.js';

export default class extends Controller<HTMLInputElement> {
  public static values = { url: String };

  public declare readonly urlValue: string;

  public connect(): void {
    this.element.addEventListener('input', () => {
      debounce(() => {
        this.sendValue(this.element.value);
      }, 1000)();
    });
  }

  private sendValue(value: string) {
    post(this.urlValue, { body: { value } })
      .then((res) => res.json)
      .then((res: { error?: string }) => {
        if (!('error' in res)) return;

        window.getSnackbar().error(res.error);
      });
  }
}
