import { StreamActions, StreamElement } from '@hotwired/turbo';
import { Modal } from 'bootstrap';

StreamActions.bootstrapModal = function (this: StreamElement) {
  const modalId = this.getAttribute('modal_id');
  const method = this.getAttribute('method');

  const modal = Modal.getOrCreateInstance(`#${modalId}`);
  if (!modal) {
    console.warn(`Modal with ID: ${modalId} not found!`);
    return;
  }

  if (method !== 'show' && method !== 'hide' && method !== 'toggle') {
    console.warn("Wrong method used. Use either 'show', 'hide' or 'toggle'");
  }

  if (method === 'show') modal.show();
  if (method === 'hide') modal.hide();
  if (method === 'toggle') modal.toggle();
};
