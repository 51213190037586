import { Article } from '../../interfaces/article.interface';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['tableBody', 'search'];

  public declare readonly tableBodyTarget: HTMLElement;
  public declare readonly searchTarget: HTMLInputElement;

  public connect() {
    this.fillModalTableBody();
  }

  public searchArticles(event: KeyboardEvent) {
    if (event.type === 'keypress' && event.code !== 'Enter') {
      return;
    }

    this.fillModalTableBody($(this.searchTarget).val().toString());
  }

  public selectArticle(event: PointerEvent) {
    const eventTarget = $(event.target);

    $.getScript(`append_article.js?article_id=${eventTarget.attr('item-id')}`);
  }

  private fillModalTableBody(search = '') {
    fetch(`/super_admin/articles.json?per_page=25&term=${search}`)
      .then((res) => res.json())
      .then((res) => {
        $(this.tableBodyTarget).children().remove();
        res.forEach((item: Article) => {
          $(this.tableBodyTarget).append(this.articleToModalHtml(item));
        });
      });
  }

  private articleToModalHtml(item: Article) {
    return `
      <tr class="item">
        <td class="icon">
          <button class="btn" data-action="super-admin--articles-modal#selectArticle" item-id="${item.id}">
            <i class="fas fa-plus color-primary"></i>
          </button>
        </td>
        <td>${item.name}</td>
        <td>${item.upc || ''}</td>
        <td>${item.factory_number || ''}</td>
        <td>${item.asin || ''}</td>
      </tr>
    `;
  }
}
