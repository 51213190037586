import { Controller } from '@hotwired/stimulus';
import { V2__Order__ArticleContainerController } from './article_container_controller';
import { debounce } from '../../../debounce';

export default class extends Controller {
  public static targets = ['input'];

  public declare readonly inputTarget: HTMLInputElement;

  public search = debounce(() => this.searchArticles());

  private searchArticles() {
    const articles = this.element.querySelectorAll<HTMLElement>('.article-name');
    const searchValue = this.inputTarget.value;
    if (searchValue === '') this.resetContainersToClosed();

    articles.forEach((article) => {
      const articleBox = article.closest<HTMLDivElement>('.article');

      if (article.textContent.toLowerCase().includes(searchValue.toLowerCase())) {
        articleBox.classList.remove('d-none');
      } else {
        articleBox.classList.add('d-none');
      }
    });

    this.element.querySelectorAll('.article-container').forEach((container) => {
      if (container.querySelector('.article:not(.d-none)')) {
        container.classList.remove('d-none');
        this.getController(container).open(true, false);
      } else {
        container.classList.add('d-none');
        if (container.getAttribute('should-be-closed') === 'true') {
          this.getController(container).close(false);
        }
      }
    });
  }

  private resetContainersToClosed() {
    this.element.querySelectorAll('.article-container[should-be-closed="true"]').forEach((container) => {
      this.getController(container).close(false);
    });
  }

  private getController(element: Element) {
    return this.application.getControllerForElementAndIdentifier(
      element,
      'v2--order--article-container',
    ) as V2__Order__ArticleContainerController;
  }
}
