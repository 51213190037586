import { CnSelect } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';
import csrfToken from '../../csrf_meta_token';

export default class extends Controller<CnSelect> {
  public static values = { debitorCount: Number, firstDebitorName: String, url: String };

  public declare debitorCountValue: number;
  public declare selectedDebitorNameValue: string;
  public declare firstDebitorNameValue: string;
  public declare urlValue: string;

  public connect() {
    if (this.debitorCountValue === 1) {
      this.sendRequest(this.firstDebitorNameValue);
      this.element.value = [this.firstDebitorNameValue];
    }
    this.element.addEventListener('change', this.debitorSelected.bind(this));
  }

  private debitorSelected() {
    this.sendRequest(this.element.value[0]);
  }

  private sendRequest(debitor: string) {
    $.ajax({
      url: this.urlValue,
      type: 'PUT',
      data: {
        debitor: debitor,
        authenticity_token: csrfToken,
      },
    });
  }
}
