import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets: string[] = ['counter', 'input'];

  public declare readonly counterTarget: HTMLSpanElement;
  public declare readonly inputTarget: HTMLInputElement;

  public connect() {
    this.updateCounter();
  }

  public updateCounter() {
    this.counterTarget.textContent = this.inputTarget.value.length.toString();
  }
}
