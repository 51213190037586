import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['input', 'progress', 'hiddenValue'];
  public static values = { url: String, callbackurl: String };

  public declare readonly inputTarget: HTMLInputElement;
  public declare readonly progressTarget: HTMLDivElement;
  public declare readonly hasProgressTarget: boolean;
  public declare readonly hiddenValueTarget: HTMLInputElement;
  public declare readonly hasHiddenValueTarget: boolean;

  public declare readonly urlValue: string;
  public declare readonly hasUrlValue: boolean;
  public declare readonly callbackurlValue: string;
  public declare readonly hasCallbackurlValue: boolean;

  public connect(): void {
    this.init();
  }

  public urlValueChanged() {
    this.init();
  }

  private init() {
    const input = $(this.inputTarget);
    if (!this.hasUrlValue) {
      throw 'data-autocomplete-url-value has to be defined';
    }

    const progressBar = this.hasProgressTarget ? $(this.progressTarget) : $(null);
    const callbackUrlValue = this.hasCallbackurlValue ? this.callbackurlValue : '';

    let hiddenValue: JQuery<HTMLInputElement>;
    if (this.hasHiddenValueTarget) hiddenValue = $(this.hiddenValueTarget);

    input
      .autocomplete({
        source: this.urlValue,
        autoFocus: true,
        minLength: 0,
        response: function () {
          progressBar.addClass('hide');
        },
        search: function () {
          progressBar.removeClass('hide');
        },
        focus: function (event) {
          event.preventDefault();
        },
        close: function () {
          // clear the hidden value when autocomplete is closed empty
          if (input.val() === '') hiddenValue.val('');
        },
        select: function (event, ui) {
          if (callbackUrlValue) {
            event.preventDefault();
            $.get({
              url: callbackUrlValue,
              dataType: 'script',
              data: ui.item,
            });
          }

          if (hiddenValue) {
            event.preventDefault();
            input.val(ui.item.label || ui.item.value);
            hiddenValue.val(ui.item.value || ui.item.label);
          }
        },
      })
      .on('focus', function () {
        $(this).data('uiAutocomplete').search();
      });
  }
}
