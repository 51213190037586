import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

export default class extends Controller {
  public static targets = ['area', 'fileField'];

  public declare readonly areaTarget: HTMLDivElement;
  public declare readonly fileFieldTarget: HTMLInputElement;

  public connect() {
    this.areaTarget.addEventListener('dragover', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });

    this.areaTarget.addEventListener('dragleave', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });

    this.areaTarget.addEventListener('drop', (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.sendRequest(event.dataTransfer.files[0]);
    });

    this.areaTarget.addEventListener('click', () => {
      this.fileFieldTarget.click();
    });

    this.fileFieldTarget.addEventListener('change', () => {
      if (!this.fileFieldTarget.files[0]) return;

      this.sendRequest(this.fileFieldTarget.files[0]);
    });
  }

  private sendRequest(file: File) {
    const fd = new FormData();
    fd.append('file', file);

    post('file_upload', {
      body: fd,
      responseKind: 'turbo-stream',
    });
  }
}
