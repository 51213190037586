import { Controller } from '@hotwired/stimulus';
import { getCookieValue } from '../../cookies';

export default class extends Controller {
  public connect() {
    if (getCookieValue('theme') === 'dark') {
      document.querySelector('html').classList.add('dark');
    } else if (getCookieValue('theme') === 'light') {
      document.querySelector('html').classList.remove('dark');
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.cookie = `theme=dark;path=/;`;
      document.querySelector('html').classList.add('dark');
    }
  }

  public toggle() {
    const rootElement = document.querySelector('html');
    const newTheme = rootElement.classList.contains('dark') ? 'light' : 'dark';
    document.cookie = `theme=${newTheme};path=/;`;
    rootElement.classList.toggle('dark', newTheme === 'dark');
    this.toggleThemeSwitchIcon(newTheme === 'dark');
  }

  private toggleThemeSwitchIcon(darkTheme: boolean) {
    document.querySelector('i[data-controller="super-admin--theme"]').classList.toggle('fa-moon', !darkTheme);
    document.querySelector('i[data-controller="super-admin--theme"]').classList.toggle('fa-sun', darkTheme);
  }
}
