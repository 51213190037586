import { Controller } from '@hotwired/stimulus';
import { escapeRegex } from '../../regex';

export default class extends Controller {
  public static targets = ['catalogSelector', 'item'];

  public declare readonly catalogSelectorTargets: HTMLAnchorElement[];
  public declare readonly itemTargets: HTMLDivElement[];

  public connect() {
    this.catalogSelectorTargets[0].classList.add('secondary-underscore', 'fw-bold');
  }

  public search(event: InputEvent) {
    this.itemTargets.forEach((item) => {
      const regex = new RegExp(`.*${escapeRegex((event.target as HTMLInputElement).value.toLowerCase())}.*`);

      if (!regex.test(item.getAttribute('itemName').toLowerCase())) {
        item.classList.add('d-none');
      } else {
        item.classList.remove('d-none');
      }
    });
  }
}
