import { Controller } from '@hotwired/stimulus';
import csrfToken from '../csrf_meta_token';

export default class extends Controller<HTMLAnchorElement> {
  public static values = { url: String };

  public declare readonly urlValue: string;

  public logout(event: Event) {
    event.preventDefault();
    sessionStorage.clear();

    const form = this.createForm();
    const submitButton = this.createSubmitButton();
    form.appendChild(this.createMethodInput());
    form.appendChild(submitButton);
    form.appendChild(this.createTokenInput());

    document.body.appendChild(form);

    submitButton.click();
  }

  private createForm(): HTMLFormElement {
    const newForm = document.createElement('form');

    newForm.method = 'post';
    newForm.action = this.urlValue;

    return newForm;
  }

  private createMethodInput(): HTMLInputElement {
    const newInput = document.createElement('input');

    newInput.type = 'hidden';
    newInput.name = '_method';
    newInput.value = 'delete';
    newInput.autocomplete = 'off';

    return newInput;
  }

  private createSubmitButton(): HTMLButtonElement {
    const newButton = document.createElement('button');

    newButton.type = 'submit';
    newButton.setAttribute('data-turbo', 'false');

    return newButton;
  }

  private createTokenInput(): HTMLInputElement {
    const newInput = document.createElement('input');

    newInput.type = 'hidden';
    newInput.name = 'authenticity_token';
    newInput.value = csrfToken;
    newInput.autocomplete = 'off';

    return newInput;
  }
}
