$(document).on('turbo:load', function () {
  const customerDevicesAutocomplete = $('#customer-devices-autocomplete');

  customerDevicesAutocomplete.autocomplete({
    source: '/customer_devices',
    autoFocus: true,
    select: function (event, ui) {
      const url = customerDevicesAutocomplete[0].dataset.targetBase;
      Turbo.visit(`${url}?${get_params(ui.item.id)}`);
    },
  });

  function get_params(deviceId) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('device_id', deviceId);
    return urlParams.toString();
  }
});
