import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['template', 'container'];

  public declare readonly templateTarget: HTMLElement;
  public declare readonly hasTemplateTarget: boolean;
  public declare readonly containerTarget: HTMLDivElement;

  private replacementId = 10000;

  public connect() {
    if (this.hasTemplateTarget) {
      const template = this.templateTarget.firstChild;
      this.replaceNameId(template as HTMLElement, 0, 'replaceme');
    }
  }

  // Use 'replaceme' as the id attribute on the root element of the delete button
  // and for attribute on  the delete button in the template.
  public add() {
    const clone = this.templateTarget.firstChild.cloneNode(true);
    const node = this.containerTarget.appendChild(clone);

    const elements = $(node).find<HTMLInputElement>('*[data-replace-name]');
    elements.map((i) => (elements[i].name = (elements[i] as HTMLElement).dataset.replaceName));

    const idMap = {};
    this.replaceIdentifier(node as HTMLElement, 'id', idMap);

    this.replaceNameId(node as HTMLElement, 'replaceme', this.replacementId++);

    const replaceElements = $(node).find('*');
    replaceElements.each((elementIndex) => {
      const element = replaceElements[elementIndex];
      this.replaceIdentifier(element, 'id', idMap);
      this.replaceIdentifier(element, 'for', idMap);
    });
  }

  public delete(event: PointerEvent) {
    const id = (event.target as HTMLElement).getAttribute('for');
    const root = $(`#${id}`);
    const destroyFlag = root.find('.destroy-flag');

    if (destroyFlag.length >= 1) {
      destroyFlag.val(1);
      root.addClass('hide');
    } else {
      root.remove();
    }
  }

  private replaceIdentifier(element: HTMLElement, attribute: string, map: Record<string, any>) {
    const identifier = element.getAttribute(attribute);

    if (identifier) {
      let id = map[identifier];
      if (!id) {
        id = this.uniqueId();
        map[identifier] = id;
      }

      element.setAttribute(attribute, id);
    }
  }

  private replaceNameId(root: HTMLElement, findId: string | number, replacementId: string | number) {
    const nameFields = $(root).find<HTMLInputElement>(`*[name*="_attributes][${findId}]"]`);
    nameFields.each((i) => {
      nameFields[i].name = nameFields[i].name.replace(`_attributes]\[${findId}]`, `_attributes][${replacementId}]`);
    });
  }

  private uniqueId() {
    return '' + new Date().valueOf() + Math.random().toString(36).substring(2, 9);
  }
}
