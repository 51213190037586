import { CnDashboardWidget } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export class WidgetController extends Controller<CnDashboardWidget> {
  public static values = { rendering: Boolean };

  public declare renderingValue: boolean;

  public connect() {
    this.element.addEventListener('show', () => this.requestAndRenderWidget());

    if (!this.renderingValue && localStorage.getItem(`widget[${this.element.name}]`) !== 'false') {
      this.requestAndRenderWidget();
    }
  }

  private requestAndRenderWidget() {
    get(`/v4/dashboard/widget?name=${this.element.name}`, {
      responseKind: 'turbo-stream',
    });
  }
}
