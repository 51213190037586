import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['fileInput', 'fileName', 'enableButton'];

  public declare readonly fileInputTarget: HTMLInputElement;
  public declare readonly fileNameTarget: HTMLInputElement;
  public declare readonly enableButtonTarget: HTMLInputElement | HTMLButtonElement;
  public declare readonly hasFileNameTarget: boolean;
  public declare readonly hasEnableButtonTarget: boolean;

  public openDialog(event: PointerEvent) {
    event.preventDefault();
    this.fileInputTarget.click();
  }

  public fileSelected() {
    if (this.hasFileNameTarget)
      this.fileNameTarget.value = this.fileInputTarget.value.toString().replace(/^.*[\\\/]/, '');

    if (this.hasEnableButtonTarget) this.enableButtonTarget.disabled = false;
  }
}
