import Rails from '@rails/ujs';

Rails.confirm = function (message: string, element: HTMLElement) {
  const translations = JSON.parse(element.getAttribute('data-translation'));

  const modalHtml = $(`
    <div class="modal falde" id="clear-confirm-modal" tabindex="-1" role="dialog" aria-labelledby="clearConfirmModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document" style="max-width:26%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">${translations[2]}</h5>
            <button class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <p>${message}</p>
          </div>

          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">${translations[1]}</button>
            <button class="btn btn-danger" type="button" id="confirm-dialog-button">${translations[0]}</button>
          </div>
        </div>
      </div>
    </div>
  `);

  $('body').append(modalHtml);

  $('#confirm-dialog-button').on('click', function () {
    $('#clear-confirm-modal').modal('hide');
    const old = Rails.confirm;
    Rails.confirm = function () {
      return true;
    };
    $<HTMLElement>(element).get(0).click();
    Rails.confirm = old;
    $(modalHtml).remove();
    $(document).trigger('turbo:visit');
  });

  $('#clear-confirm-modal').on('hidden.bs.modal', function () {
    $(modalHtml).remove();
  });

  $('#clear-confirm-modal').modal('show');
  return false;
};
