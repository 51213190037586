import Chart from 'chart.js/auto';
import { ChartData } from '../../interfaces/chart_data.interface';
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { isDarkMode } from '../../is_dark_mode';
import { stringToColor } from '../../string_to_color';

export class PieController extends Controller {
  public static targets = ['canvas'];
  public static values = { data: Array, name: String, donut: Boolean };

  public declare readonly canvasTarget: HTMLCanvasElement;
  public declare readonly dataValue: ChartData[];
  public declare readonly nameValue: string;
  public declare readonly donutValue: boolean;

  private chart: undefined | Chart<'pie' | 'doughnut'>;

  public connect() {
    this.setupChart();
    this.listenSidenavToggling();
    this.listenResize();
  }

  private setupChart() {
    Chart.defaults.color = isDarkMode ? '#fff' : Chart.defaults.color.toString();
    this.chart = new Chart(this.canvasTarget, {
      type: this.donutValue ? 'doughnut' : 'pie',
      data: {
        labels: this.dataValue.map((data) => data.text.substring(0, 25).padEnd(25)),
        datasets: [
          {
            hoverOffset: 5,
            backgroundColor: this.dataValue.map((data) => stringToColor(data.text)),
            data: this.dataValue.map((data) => data.value),
            borderWidth: 1,
            borderAlign: 'inner',
            borderColor: 'black',
          },
        ],
      },
      options: {
        cutout: this.donutValue ? '80%' : '0%',
        maintainAspectRatio: false,
        animation: {
          animateScale: false,
        },
        plugins: {
          tooltip: {
            titleFont: {
              size: 0,
            },
            callbacks: {
              label: (tooltipItem) => {
                return this.dataValue[tooltipItem.dataIndex].text;
              },
            },
          },
          legend: {
            position: 'right',
            align: 'start',
            onClick: (_, legendItem) => {
              this.chart.toggleDataVisibility(legendItem.index);
              this.chart.update();

              const data = this.dataValue.find((d) => d.text === legendItem.text);
              if (!data || !data.name) return;

              localStorage.setItem(
                `chart[${this.nameValue}][${data.name}]`,
                this.chart.getDataVisibility(legendItem.index).toString(),
              );
            },
          },
          datalabels: {
            color: '#fff',
          },
        },
        onHover: (event, elements) => {
          const eventTarget = event.native.target as HTMLCanvasElement;
          eventTarget.style.cursor = elements[0] ? 'pointer' : 'default';
        },
        onClick: (_, elements) => {
          const data = this.dataValue[elements[0]?.index];
          if (!data) return;

          Turbo.visit(data.link);
        },
      },
    });

    this.hideDisabledLabels();
  }

  private hideDisabledLabels() {
    this.dataValue.forEach((data) => {
      if (localStorage.getItem(`chart[${this.nameValue}][${data.name}]`) === 'false') {
        const index = this.dataValue.findIndex((d) => d.name === data.name);

        this.chart.toggleDataVisibility(index);
      }
    });

    this.chart.update();
  }

  private listenResize() {
    window.addEventListener('resize', () => {
      if (!this.chart) return;

      this.chart.destroy();
      this.setupChart();
    });
  }

  private listenSidenavToggling() {
    document.querySelector('cn-sidenav')?.addEventListener('toggling', () => {
      if (!this.chart) return;

      this.chart.destroy();
      // Wait for sidenav animation
      setTimeout(() => {
        this.setupChart();
      }, 500);
    });
  }
}
