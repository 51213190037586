import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { escapeRegex } from '../regex';

export default class extends Controller {
  public static targets = ['formField', 'valueText', 'floatingLabel'];
  public static values = {
    id: String,
    filter: String,
    removeParams: Array,
    default: Array,
    multi: Boolean,
    submitForm: String,
  };

  public declare readonly formFieldTarget: HTMLInputElement;
  public declare readonly valueTextTarget: HTMLSpanElement;
  public declare readonly floatingLabelTarget: HTMLSpanElement;

  public declare readonly idValue: string;
  public declare readonly filterValue: string;
  public declare readonly hasFilterValue: boolean;
  public declare readonly removeParamsValue: any[];
  public declare readonly defaultValue: any[];
  public declare readonly hasDefaultValue: boolean;
  public declare readonly multiValue: boolean;
  public declare readonly submitFormValue: string;
  public declare readonly hasSubmitFormValue: boolean;

  public connect() {
    const params = new URLSearchParams(window.location.search);
    if (this.hasFilterValue && params.get(this.filterValue)) {
      $(
        `#${this.idValue}.dropdown .dropdown-menu li[value="${params.get(this.filterValue).replace(/"/g, '\\"')}"]`,
      ).trigger('click');
    } else if (this.hasDefaultValue) {
      this.defaultValue.forEach((value) => {
        value = value.toString();
        const selectedListItem = $(
          `#${this.idValue}.dropdown .dropdown-menu li[value="${value.replace(/"/g, '\\"')}"]`,
        );
        if (this.multiValue) {
          selectedListItem.find(`label`).trigger('click');
        } else {
          selectedListItem.trigger('click');
        }
      });
    }
  }

  public select(event: PointerEvent) {
    let eventTarget: JQuery<HTMLElement> = $(event.target as HTMLElement);
    let formFieldValue = '';
    let valueText = '';
    let labelShouldToggle = false;

    if (eventTarget.hasClass('cn-select-button') && !eventTarget.hasClass('fa-square')) {
      return;
    }

    if (this.multiValue) {
      const labels = [];
      const values = [];

      if (!eventTarget.hasClass('cn-select-button-label')) {
        eventTarget = eventTarget.parent();
      }

      eventTarget
        .parent()
        .parent()
        .children()
        .each(function () {
          const option = $(this);
          if (
            (option.find('.cn-select-button').is(':checked') && eventTarget.parent()[0] !== option[0]) ||
            (eventTarget.parent()[0] === option[0] && !option.find('.cn-select-button').is(':checked'))
          ) {
            labels.push(option.text());
            values.push(option.attr('value'));
          }
        });

      valueText = labels.join(', ');
      formFieldValue = values.toString();
      labelShouldToggle = labels.length === 0;
    } else {
      valueText = eventTarget.text();
      formFieldValue = eventTarget.attr('value');
      labelShouldToggle = eventTarget.attr('value') === '';
    }

    $(this.valueTextTarget).text(valueText);
    $(this.formFieldTarget).val(formFieldValue);
    this.highlightSelectedElement(formFieldValue);
    $(this.formFieldTarget).trigger('change');
    $(`.dropdown#${this.idValue}`).trigger('cn-select-change', formFieldValue);
    this.toggleLabel(labelShouldToggle);

    if (this.hasFilterValue) {
      const params = new URLSearchParams(window.location.search);
      const targetValue = eventTarget.attr('value');
      if (
        (params.has(this.filterValue) || targetValue !== 'all') &&
        params.get(this.filterValue) !== eventTarget.attr('value')
      ) {
        this.applyFilter(targetValue);
      }
    }

    if (this.hasSubmitFormValue) {
      this.submitForm();
    }
  }

  public search(event: InputEvent) {
    const eventTarget = $(event.target);
    eventTarget
      .parent()
      .parent()
      .children()
      .each(function () {
        const option = $(this);
        if (option.hasClass('cn-select-option')) {
          const regex = new RegExp(`.*${escapeRegex(eventTarget.val().toString().toLowerCase())}.*`);

          if (!regex.test(option.text().toLowerCase())) {
            option.css('display', 'none');
          } else {
            option.css('display', 'block');
          }
        }
      });
  }

  private applyFilter(targetValue: string) {
    const path = window.location.pathname;
    const params = new URLSearchParams(window.location.search);

    this.removeParamsValue.forEach((param) => params.delete(param));

    if (!targetValue || targetValue === '' || targetValue === 'all') {
      params.delete(this.filterValue);
    } else {
      params.set(this.filterValue, targetValue);
    }

    Turbo.visit(path + '?' + params.toString());
  }

  private toggleLabel(showPlaceholder: boolean) {
    if (showPlaceholder) {
      this.valueTextTarget.classList.add('placeholder');
      this.floatingLabelTarget.classList.add('hidden');
      $(this.valueTextTarget).text($(this.floatingLabelTarget).text());
    } else {
      this.valueTextTarget.classList.remove('placeholder');
      this.floatingLabelTarget.classList.remove('hidden');
    }
  }

  private submitForm() {
    $(`#${this.submitFormValue}`).append(
      '<input type="submit" name="commit" value="Save changes" data-disable-with="Save changes" style="display: none">',
    );
    $(`#${this.submitFormValue}`).append(`<input type="hidden" name="default_value" value="${this.defaultValue}">`);
    $(`#${this.submitFormValue} input[type="submit"]`).trigger('click');
    $(`#${this.submitFormValue} input[type="submit"]`).remove();
  }

  private highlightSelectedElement(selectedValue: string) {
    const selectOptions = $(`.dropdown-menu[aria-labelledby="${this.idValue}"] li`);

    selectOptions.each(function () {
      const option = $(this);
      option.removeClass('selected');

      if (option.attr('value') === selectedValue) {
        option.addClass('selected');
      }
    });
  }
}
