import { CnTab, CnTabGroup } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller<CnTabGroup> {
  private tabChangeFunction;

  public connect() {
    this.tabChangeFunction = this.tabChanged.bind(this);
    this.element.addEventListener('tabChange', this.tabChangeFunction, true);
    this.selectInitialTab();

    document.addEventListener('turbo:render', () => {
      this.selectInitialTab();
    });
  }

  public disconnect() {
    this.element.removeEventListener('tabChange', this.tabChangeFunction, true);
  }

  private selectInitialTab() {
    const urlParams = new URLSearchParams(window.location.search);
    const initialTabName = urlParams.has('tab') ? urlParams.get('tab') : 'orders';

    const initalTab = this.element.querySelector<CnTab>(`cn-tab[data-tab-name="${initialTabName}"]`);
    initalTab.click();
  }

  private tabChanged(event: CustomEvent) {
    event.preventDefault();
    const tab = event.detail as CnTab;

    const tabName = tab.getAttribute('data-tab-name');
    const tabId = tab.tabId;
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.set('tab', tabName);
    const newUrl = `${location.protocol}//${location.host}${location.pathname}?${urlParams.toString()}`;

    fetch(`${location.pathname}/${tabName}`, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        history.replaceState(history.state, '', newUrl);

        // Needs some time to render
        setTimeout(() => {
          this.element.selectTab(tabId);
        }, 100);
      });
  }
}
