import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static values = { completed: Boolean };

  public declare readonly completedValue: boolean;

  public connect() {
    if (this.completedValue) {
      $('#boarding-completed-modal').modal('show');
    }
  }
}
