import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public connect() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('send_to_lessor_completed') === 'true') {
      $('#send-to-lessor-confirmation-modal').modal('show');
    }
  }
}
