import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  public setPerPage(event: PointerEvent) {
    const perPage = (event.target as HTMLButtonElement).value;

    const path = window.location.pathname;
    const params = new URLSearchParams(window.location.search);

    if (!perPage || perPage === '') {
      params.delete('per_page');
    } else {
      params.set('per_page', perPage);
    }

    Turbo.visit(`${path}?${params}`);
  }
}
