import { CnRadioButton, CnSelect } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';
import { Turbo } from '@hotwired/turbo-rails';
import { debounce } from '../debounce';

export default class extends Controller<HTMLDivElement> {
  public static targets = ['input'];

  public declare readonly inputTarget: HTMLInputElement;

  public search = debounce(() => this.sendSearchRequest());

  private get addressBookModal(): Modal {
    return Modal.getInstance(this.element);
  }

  public selectAddress() {
    const selectedAddress = [...this.element.querySelectorAll<CnRadioButton>('cn-radio-button')].find(
      (radioButton) => radioButton.checked,
    );
    this.assignToOtherAddress(`#${selectedAddress.parentElement.id}`);
  }

  private assignToOtherAddress(baseElementSelector: string) {
    document
      .querySelectorAll<HTMLInputElement | CnSelect>('.other-address cn-input-wrapper input, cn-select')
      .forEach((input) => {
        const sourceElement = document.querySelector<HTMLInputElement>(
          `${baseElementSelector} input[data-address-tile="${input.name}"]`,
        );

        if (sourceElement) input.value = sourceElement.value;
        if (!sourceElement) input.value = null;
      });

    document.querySelector<CnRadioButton>('cn-radio-button.address-switch[value="custom"]').check();
    this.addressBookModal.hide();
  }

  private sendSearchRequest() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('exclude_insight_addresses', 'true');
    urlParams.set('filters[search]', this.inputTarget.value);

    fetch(`/v2/addresses?${urlParams.toString()}`, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}
