import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['radioButton', 'input'];

  public declare readonly radioButtonTarget: HTMLInputElement;
  public declare readonly inputTarget: HTMLInputElement;

  public connect() {
    this.radioButtonTarget.addEventListener('change', () => {
      this.refresh(this.radioButtonTarget.checked);
    });
  }

  public refresh(selected: boolean) {
    if (selected) {
      this.inputTarget.parentElement.classList.remove('d-none');
      this.inputTarget.disabled = false;
    } else {
      this.inputTarget.parentElement.classList.add('d-none');
      this.inputTarget.disabled = true;
    }
  }
}
