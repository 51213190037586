import Rails from '@rails/ujs';
Rails.start();

// eslint-disable-next-line sort-imports
import './import_jquery';
import 'jquery-ui-dist/jquery-ui';
import 'bootstrap/dist/js/bootstrap';
import './controllers';
import './customer_devices_autocomplete';
import './custom_confirm';
import '@rails/actiontext';
import 'trix';
import './trix';
import './turbo_actions';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CnSnackbarContainer } from '@creative-network/hencke-layout';
import { Turbo } from '@hotwired/turbo-rails';
import TurboPower from 'turbo_power';

TurboPower.initialize(Turbo.StreamActions);
Chart.register(ChartDataLabels);
window.getSnackbar = () => document.querySelector('cn-snackbar-container');

declare global {
  interface Window {
    getSnackbar: () => CnSnackbarContainer;
  }
}

document.querySelectorAll('turbo-cable-stream-source').forEach((source) => {
  source.addEventListener('message', (event: MessageEvent) => {
    Turbo.renderStreamMessage(event.data);
  });
});
