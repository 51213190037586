import { CnSelect } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['select'];

  public declare readonly selectTarget: CnSelect;

  public connect() {
    this.selectTarget.addEventListener('change', this.banChanged.bind(this));
    this.banChanged();
  }

  private banChanged() {
    document.querySelector<HTMLInputElement>('.submit-order-button').disabled = true;

    const submitFormInput = this.submitFormInput();
    const defaultValueInput = this.defaultValueInput();
    const banValueInput = this.banValueInput();

    this.element.appendChild(submitFormInput);
    this.element.appendChild(defaultValueInput);
    this.element.appendChild(banValueInput);

    submitFormInput.click();

    submitFormInput.remove();
    defaultValueInput.remove();
    banValueInput.remove();
  }

  private submitFormInput(): HTMLInputElement {
    const input = document.createElement('input');

    input.type = 'submit';
    input.name = 'commit';
    input.value = 'Save changes';
    input.setAttribute('data-disable-with', 'Save changes');
    input.style.display = 'none';

    return input;
  }

  private defaultValueInput(): HTMLInputElement {
    const input = document.createElement('input');

    input.type = 'hidden';
    input.name = 'default_value';
    input.value = this.selectTarget.getAttribute('defaultValue');

    return input;
  }

  private banValueInput(): HTMLInputElement {
    const input = document.createElement('input');

    input.type = 'hidden';
    input.name = 'ban';
    input.value = this.selectTarget.value[0];

    return input;
  }
}
