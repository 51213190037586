import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['block', 'submit', 'parent'];

  public declare readonly blockTarget: HTMLDivElement;
  public declare readonly submitTarget: HTMLButtonElement;
  public declare readonly parentTarget: HTMLDivElement;
  public declare readonly hasParentTarget: boolean;
  public declare readonly hasSubmitTarget: boolean;

  public connect() {
    if (this.hasParentTarget) {
      const selectedOption = $(this.parentTarget).find('input[type="radio"]:selected');

      if (selectedOption.length === 0) {
        // No option is selected
        $(this.parentTarget).find('input[type="radio"]').first().trigger('click');
      }
    }
  }

  public select(event: Event) {
    const hide = (event.target as HTMLElement).dataset.radioSelectOption === 'hide';

    if (hide) {
      this.blockTarget.classList.add('hide');
      if (this.hasSubmitTarget) {
        this.submitTarget.value = this.submitTarget.dataset.shownSubmit;
      }
    } else {
      this.blockTarget.classList.remove('hide');
      if (this.hasSubmitTarget) {
        this.submitTarget.value = this.submitTarget.dataset.hiddenSubmit;
      }
    }
  }
}
