import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = [
    'modal',
    'itemIdsContainer',
    'tradeInToggle',
    'tradeInFormFlag',
    'purchaseToggle',
    'rentToggle',
    'afsLeasingToggle',
  ];

  public declare readonly modalTarget: HTMLInputElement;
  public declare readonly itemIdsContainerTarget: HTMLDivElement;
  public declare readonly tradeInToggleTarget: HTMLInputElement;
  public declare readonly tradeInFormFlagTarget: HTMLInputElement;
  public declare readonly purchaseToggleTarget: HTMLInputElement;
  public declare readonly rentToggleTarget: HTMLInputElement;
  public declare readonly afsLeasingToggleTarget: HTMLInputElement;

  public declare readonly hasModalTarget: boolean;
  public declare readonly hasItemIdsContainerTarget: boolean;
  public declare readonly hasTradeInToggleTarget: boolean;
  public declare readonly hasTradeInFormFlagTarget: boolean;
  public declare readonly hasPurchaseToggleTarget: boolean;
  public declare readonly hasRentToggleTarget: boolean;
  public declare readonly hasAfsLeasingToggleTarget: boolean;

  public connect() {
    this.tradeInToggleTarget.addEventListener('change', () => this.updateDisplay());
    this.purchaseToggleTarget.addEventListener('change', () => this.updateDisplay());
    this.rentToggleTarget.addEventListener('change', () => this.updateDisplay());
    this.afsLeasingToggleTarget.addEventListener('change', () => this.updateDisplay());
  }

  public updateDisplay() {
    const elementsToHide = [];
    const elementsToShow = [];

    // trade-in
    const tradeInTargets = $(this.element)
      .find(
        '.one-time-payment-column, .old-to-new-icon, input[name="old_tariff_select"], input[name="old_device_select"]',
      )
      .toArray();
    (this.tradeInToggleTarget.checked ? elementsToShow : elementsToHide).push(tradeInTargets);

    // purchase
    const purchaseTargets = $(this.element).find('.buy-column').toArray();
    (this.purchaseToggleTarget.checked ? elementsToShow : elementsToHide).push(purchaseTargets);

    // rent
    const rentTargets = $(this.element).find('.rent-column').toArray();
    (this.rentToggleTarget.checked ? elementsToShow : elementsToHide).push(rentTargets);

    // afs-leasing
    const afsLeasingTargets = $(this.element).find('.afs-leasing-column').toArray();
    (this.afsLeasingToggleTarget.checked ? elementsToShow : elementsToHide).push(afsLeasingTargets);

    elementsToHide.flat().forEach((element) => element.classList.add('hide'));
    elementsToShow.flat().forEach((element) => element.classList.remove('hide'));
  }
}
