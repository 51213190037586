import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';
import { RemarketingPrices } from '../../interfaces/remarketing_prices.interface';

export default class extends Controller {
  public static values = { prices: Object };

  public declare readonly pricesValue: RemarketingPrices;

  private get pricesModal(): HTMLDivElement {
    return document.querySelector('.modal#remarketing-prices-modal');
  }

  public showPrices() {
    this.pricesModal.querySelector('cn-table-row').innerHTML = '';

    this.tableRows().forEach((row) => {
      console.log(row);
      this.pricesModal.querySelector('cn-table-row').appendChild(row);
    });

    new Modal(this.pricesModal).show();
  }

  private tableRows(): HTMLElement[] {
    const gradeA = document.createElement('cn-table-row-cell');
    gradeA.textContent = this.pricesValue.grade_a;

    const gradeB = document.createElement('cn-table-row-cell');
    gradeB.textContent = this.pricesValue.grade_b;

    const gradeC = document.createElement('cn-table-row-cell');
    gradeC.textContent = this.pricesValue.grade_c;

    const gradeD = document.createElement('cn-table-row-cell');
    gradeD.textContent = this.pricesValue.grade_d;

    const gradeE = document.createElement('cn-table-row-cell');
    gradeE.textContent = this.pricesValue.grade_e;

    const gradeF = document.createElement('cn-table-row-cell');
    gradeF.textContent = this.pricesValue.grade_f;

    return [gradeA, gradeB, gradeC, gradeD, gradeE, gradeF];
  }
}
