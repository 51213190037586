import { Controller } from '@hotwired/stimulus';
import { escapeRegex } from '../../regex';

export default class extends Controller {
  public static targets = ['item'];

  public declare readonly itemTargets: HTMLDivElement[];

  public search(event: InputEvent) {
    const eventTarget = $(event.target);
    this.itemTargets.forEach((item) => {
      const option = $(item);
      const regex = new RegExp(`.*${escapeRegex(eventTarget.val().toString().toLowerCase())}.*`);

      if (!regex.test(option.find('.item-name').text().toLowerCase())) {
        option.css('display', 'none');
      } else {
        option.css('display', 'block');
      }
    });
  }
}
