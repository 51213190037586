import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['destroy', 'hide'];

  public declare readonly destroyTarget: HTMLInputElement;
  public declare readonly hideTarget: HTMLElement;

  public declare readonly hasHideTarget: boolean;

  public markForDestruction(event) {
    event.preventDefault();
    $(this.destroyTarget).val('1');

    if (this.hasHideTarget) {
      $(this.hideTarget).addClass('d-none');
    }
  }
}
