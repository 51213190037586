import { Controller } from '@hotwired/stimulus';

export class DateFilterController extends Controller<HTMLInputElement> {
  public static values = { direction: String, filterName: String };

  public declare readonly directionValue: 'from' | 'to';
  public declare readonly filterNameValue: string;

  public connect() {
    const paramName = `filters[${this.filterNameValue}][${this.directionValue}]`;
    const filter = document.querySelector('cn-table-filter');
    this.element.value = filter.filterParams.get(paramName);

    this.element.addEventListener('change', () => {
      if (this.element.value) {
        filter.filterParams.set(paramName, this.element.value);
      } else {
        filter.filterParams.delete(paramName);
      }
    });
  }
}
