import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  public static targets = ['tableBody', 'search'];

  public declare readonly tableBodyTarget: HTMLElement;
  public declare readonly searchTarget: HTMLInputElement;

  public connect() {
    this.getErpArticles();
  }

  public searchArticles(event: KeyboardEvent) {
    if (event.type === 'keypress' && event.code === 'Enter') {
      this.getErpArticles(this.searchTarget.value.toString());
    }
  }

  public selectArticle(event: PointerEvent) {
    const erpArticleId = (event.target as HTMLElement).getAttribute('erp-article-id');

    fetch(`append_article?erp_article_id=${erpArticleId}`, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  private getErpArticles(search = '') {
    fetch(`/super_admin/erp_articles?per_page=25&term=${search}`, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}
