import { Controller } from '@hotwired/stimulus';
import csrfToken from '../../../../csrf_meta_token';

export default class extends Controller {
  public static values = { msisdnId: String, debitorId: String };

  public declare readonly msisdnIdValue: string;
  public declare readonly debitorIdValue: string;

  public connect() {
    fetch(`/v2/order/checkout/set_debitor?msisdn_id=${this.msisdnIdValue}`, {
      method: 'POST',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        authenticity_token: csrfToken,
        debitor_id: this.debitorIdValue,
      }),
    });
  }
}
