import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['fileInput', 'imageTag', 'purgeCheckbox', 'urlField'];
  public static values = { defaultImageUrl: String };

  public declare readonly fileInputTarget: HTMLInputElement;
  public declare readonly imageTagTarget: HTMLImageElement;
  public declare readonly purgeCheckboxTarget: HTMLInputElement;
  public declare readonly hasPurgeCheckboxTarget: boolean;
  public declare readonly urlFieldTarget: HTMLInputElement;
  public declare readonly hasUrlFieldTarget: boolean;

  public declare readonly defaultImageUrlValue: string;

  public openDialog(event: PointerEvent) {
    const eventTarget = event.target as HTMLElement;
    if (eventTarget.tagName.toLowerCase() !== 'input' && eventTarget.getAttribute('type') !== 'file') {
      event.preventDefault();
      this.fileInputTarget.click();
    }
  }

  public resetImage() {
    this.imageTagTarget.setAttribute('src', this.defaultImageUrlValue);
    this.fileInputTarget.value = null;
    if (this.hasPurgeCheckboxTarget) this.purgeCheckboxTarget.checked = true;
    if (this.hasUrlFieldTarget) this.urlFieldTarget.value = null;
  }

  public fileSelected(event: Event) {
    this.imageTagTarget.setAttribute('src', URL.createObjectURL((event.target as HTMLInputElement).files[0]));
    if (this.hasPurgeCheckboxTarget) this.purgeCheckboxTarget.checked = false;
    if (this.hasUrlFieldTarget) this.urlFieldTarget.disabled = true;
  }
}
