import { Controller } from '@hotwired/stimulus';

export class V2__Order__ArticleContainerController extends Controller {
  public static targets = ['arrow', 'list'];
  public static values = { name: String };

  public declare readonly arrowTarget: HTMLDivElement;
  public declare readonly listTarget: HTMLDivElement;

  public declare readonly nameValue: string;

  private opened: boolean;

  private get localStorageKey(): string {
    return `article-list-${this.nameValue}`;
  }

  public connect(): void {
    this.opened = localStorage.getItem(this.localStorageKey) === null;

    if (!this.opened) this.close(false);
  }

  public toggleList() {
    this.opened = !this.opened;

    if (this.opened) this.open();
    if (!this.opened) this.close();
  }

  public open(temporary = false, animate = true) {
    this.listTarget.style.height = 'auto';

    if (animate) {
      const listHeight = this.listTarget.scrollHeight;
      this.listTarget.style.height = '0px';
      setTimeout(() => (this.listTarget.style.height = `${listHeight}px`));
    }

    this.arrowTarget.classList.remove('rotated');

    if (!temporary) {
      this.element.setAttribute('should-be-closed', 'false');
      this.element.classList.remove('closed');
      localStorage.removeItem(this.localStorageKey);

      setTimeout(() => {
        this.listTarget.style.height = 'auto';
      }, 500);
    }
  }

  public close(animate = true) {
    if (animate) {
      this.listTarget.style.height = 'auto';
      const listHeight = this.listTarget.scrollHeight;
      this.listTarget.style.height = `${listHeight}px`;
    } else {
      this.listTarget.style.height = '0px';
    }

    setTimeout(() => (this.listTarget.style.height = '0px'));

    this.arrowTarget.classList.add('rotated');
    this.element.classList.add('closed');
    this.element.setAttribute('should-be-closed', 'true');
    localStorage.setItem(this.localStorageKey, 'false');
  }
}
