import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  public applySorting(event: PointerEvent) {
    const sorting = (event.currentTarget as HTMLElement).dataset.sortColumn;

    const path = window.location.pathname;
    const params = new URLSearchParams(window.location.search);

    if (!sorting || sorting === '') {
      params.delete('sort');
    } else {
      params.set('sort', sorting);
    }

    Turbo.visit(`${path}?${params}`);
  }
}
