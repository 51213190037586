import { CnOverlayPanel, CnSidePanel } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';
import { loadCartContent } from '../cart_loader';

export default class extends Controller {
  private cartLoaded = false;

  public openCart() {
    if (!this.cartLoaded) {
      loadCartContent();
      this.cartLoaded = true;
    }
    document.querySelector<CnOverlayPanel>('cn-overlay-panel#cartPanel').toggle();
  }

  public openHelp() {
    document.querySelector<CnSidePanel>('cn-sidenav-panel#helpPanel').toggle();
  }
}
