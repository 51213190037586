import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static values = {
    url: String,
    offerId: String,
    rowId: String,
    authenticityToken: String,
    campaignApplied: String,
  };
  public static targets = [
    'changeIndicator',
    'id',
    'amount',
    'oldTariffAutocomplete',
    'oldTariff',
    'newTariffAutocomplete',
    'newTariff',
    'oldDeviceAutocomplete',
    'oldDevice',
    'newDeviceAutocomplete',
    'newDevice',
    'activeFlag',
  ];

  public declare urlValue: string;
  public declare offerIdValue: string;
  public declare rowIdValue: string;
  public declare authenticityTokenValue: string;
  public declare campaignAppliedValue: 'true' | 'false';
  public declare readonly changeIndicatorTarget: HTMLImageElement;
  public declare readonly idTarget: HTMLInputElement;
  public declare readonly amountTarget: HTMLInputElement;
  public declare readonly oldTariffTarget: HTMLInputElement;
  public declare readonly oldTariffAutocompleteTarget: HTMLInputElement;
  public declare readonly newTariffTarget: HTMLInputElement;
  public declare readonly newTariffAutocompleteTarget: HTMLInputElement;
  public declare readonly oldDeviceTarget: HTMLInputElement;
  public declare readonly oldDeviceAutocompleteTarget: HTMLInputElement;
  public declare readonly newDeviceTarget: HTMLInputElement;
  public declare readonly newDeviceAutocompleteTarget: HTMLInputElement;
  public declare readonly activeFlagTarget: HTMLInputElement;

  public declare readonly hasUrlValue: boolean;
  public declare readonly hasOfferIdValue: boolean;
  public declare readonly hasRowIdValue: boolean;
  public declare readonly hasAuthenticityTokenValue: boolean;
  public declare readonly hasChangeIndicatorTarget: boolean;
  public declare readonly hasIdTarget: boolean;
  public declare readonly hasAmountTarget: boolean;
  public declare readonly hasOldTariffTarget: boolean;
  public declare readonly hasOldTariffAutocompleteTarget: boolean;
  public declare readonly hasNewTariffTarget: boolean;
  public declare readonly hasNewTariffAutocompleteTarget: boolean;
  public declare readonly hasOldDeviceTarget: boolean;
  public declare readonly hasOldDeviceAutocompleteTarget: boolean;
  public declare readonly hasNewDeviceTarget: boolean;
  public declare readonly hasNewDeviceAutocompleteTarget: boolean;
  public declare readonly hasActiveFlagTarget: boolean;

  private get persisted(): boolean {
    return !!this.idTarget.value;
  }

  private get url(): string {
    return this.idTarget.value ? `${this.urlValue}/${this.idTarget.value}` : this.urlValue;
  }

  private get itemParams(): object {
    return {
      authenticity_token: this.authenticityTokenValue,
      id: this.idTarget.value,
      row_id: this.rowIdValue,
      amount: this.amountTarget.value,
      old_tariff_id: this.oldTariffTarget.value,
      new_tariff_id: this.newTariffTarget.value,
      old_device_id: this.oldDeviceTarget.value,
      new_device_id: this.newDeviceTarget.value,
    };
  }

  public connect() {
    // We are accessing the other controller directly even though StimulusJs
    // advocates for using events to send messages to other controllers. We do
    // this because the controller we could send events to must be a parent
    // controller. Since we render new rows inside through JavaScript, presumably
    // the new row gets rendered and connected before it is at it´s place. This
    // causes the event to get lost in the void.
    const downloadParent = document.querySelector<HTMLDivElement>('*[data-controller="leasing--download"]');
    const downloadController = <any>(
      this.application.getControllerForElementAndIdentifier(downloadParent, 'leasing--download')
    );
    downloadController.updateDisplay();

    this.amountTarget.addEventListener('change', () => {
      this.markChange();
    });

    let tradeInCallback = undefined;
    if (this.campaignAppliedValue === 'true') {
      tradeInCallback = () => {
        if (!!this.oldDeviceTarget.value && !this.newDeviceTarget.value) {
          fetch(`/offers/${this.offerIdValue}/recommend_new_device?old_device_id=${this.oldDeviceTarget.value}`)
            .then((r) => r.json())
            .then((json) => {
              if (!!json.id) {
                this.newDeviceTarget.value = json.id;
                this.newDeviceAutocompleteTarget.value = json.name;
              }
            });
        }
      };
    }

    this.addAutocomplete({
      field: this.oldDeviceAutocompleteTarget,
      hiddenField: this.oldDeviceTarget,
      url: '/trade_in_device_types',
      onSelect: tradeInCallback,
    });

    this.addAutocomplete({
      field: this.newDeviceAutocompleteTarget,
      hiddenField: this.newDeviceTarget,
      url: '/device_types',
    });

    this.addAutocomplete({
      field: this.oldTariffAutocompleteTarget,
      hiddenField: this.oldTariffTarget,
      url: '/tariffs',
    });

    this.addAutocomplete({
      field: this.newTariffAutocompleteTarget,
      hiddenField: this.newTariffTarget,
      url: '/tariffs',
    });

    this.activeFlagTarget.addEventListener('change', () => {
      fetch(this.url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          authenticity_token: this.authenticityTokenValue,
          active: this.activeFlagTarget.checked,
        }),
      });
    });
  }

  public onSubmit(): void {
    const tradeInToggle = <HTMLInputElement>document.getElementById('trade-in-toggle');

    $.ajax({
      url: this.url,
      type: this.persisted ? 'PATCH' : 'POST',
      dataType: 'script',
      data: $.extend(this.itemParams, { trade_in_toggle: tradeInToggle.checked }),
    });
  }

  public onDestroy(): void {
    if (this.persisted) {
      $.ajax({
        url: this.url,
        type: 'DELETE',
        dataType: 'script',
        data: { authenticity_token: this.authenticityTokenValue },
        success: () => {
          $(this.element).remove();
        },
      });
    } else {
      $(this.element).remove();
    }
  }

  private addAutocomplete(options: {
    field: HTMLInputElement;
    hiddenField?: HTMLInputElement;
    url: string;
    onSelect?: () => unknown;
  }) {
    const _this = this;
    $(options.field)
      .autocomplete({
        source: options.url,
        autoFocus: true,
        minLength: 0,
        // response: function(event, ui) { progressBar.addClass('hide'); },
        // search: function(event, ui) { progressBar.removeClass('hide'); },
        focus: function (event) {
          event.preventDefault();
        },
        close: function () {
          // clear the hidden value when autocomplete is closed empty
          if ($(options.field).val() === '') $(options.hiddenField).val('');
          _this.markChange();
        },
        select: function (event, ui) {
          _this.markChange();
          if (options.hiddenField) {
            event.preventDefault();
            $(options.field).val(ui.item.label || ui.item.value);
            $(options.hiddenField).val(ui.item.value || ui.item.label);
          }
          if (!!options.onSelect) {
            options.onSelect();
          }
        },
      })
      .on('focus', function () {
        $(this).data('uiAutocomplete').search();
      });
  }

  private markChange() {
    $(this.changeIndicatorTarget).removeClass('hide');
  }
}
