import { CnInfoTile, CnTable, CnTablePaginator, CnTableRow } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';

const MAX_WEIGHT = 31500;
const DEVICE_WEIGHT = 350;

export default class extends Controller {
  public static targets = ['paginator', 'table', 'infoTile'];

  public declare readonly paginatorTarget: CnTablePaginator;
  public declare readonly tableTarget: CnTable;
  public declare readonly infoTileTarget: CnInfoTile;

  private get uncheckedRows(): CnTableRow[] {
    return this.tableTarget.rows.filter(
      (row) => row.matches('cn-table-row') && !(row as CnTableRow).checkboxElement.checked,
    ) as CnTableRow[];
  }

  private get currentWeight(): number {
    return this.paginatorTarget.selectedAmount * DEVICE_WEIGHT;
  }

  public connect() {
    this.evaluateCurrentWeight();
  }

  public rowClicked(event: MouseEvent) {
    if (this.currentWeightOverLimit(-DEVICE_WEIGHT)) {
      (event.target as CnTableRow).setCheckboxState(false);
    }
    this.evaluateCurrentWeight();
  }

  private evaluateCurrentWeight() {
    this.uncheckedRows.map((row) => row.classList.toggle('disabled', this.currentWeightOverLimit()));
    this.infoTileTarget.classList.toggle('d-none', !this.currentWeightOverLimit());
  }

  private currentWeightOverLimit(difference = 0): boolean {
    return this.currentWeight + difference >= MAX_WEIGHT;
  }
}
