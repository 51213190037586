import { Controller } from '@hotwired/stimulus';
import { escapeRegex } from '../../regex';

export default class extends Controller {
  public static targets = ['contractPositionName'];

  public declare readonly contractPositionNameTargets: HTMLTableColElement[];

  public search(event: InputEvent) {
    const searchText = $(event.target).val().toString();

    this.contractPositionNameTargets.forEach((element) => {
      const regex = new RegExp(`.*${escapeRegex(searchText.toLowerCase())}.*`);
      const option = $(element);

      if (!regex.test(option.text().toLowerCase())) {
        option.parent().addClass('d-none');
      } else {
        option.parent().removeClass('d-none');
      }
    });
  }
}
