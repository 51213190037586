import { Controller } from '@hotwired/stimulus';
import { insertAfter } from '../../insert';

export default class extends Controller {
  public static values = { pictureUrls: Array };

  public declare readonly pictureUrlsValue: string[];

  public moveLeft(event: MouseEvent) {
    const eventTarget = event.target as HTMLElement;
    const picture = eventTarget.closest<HTMLDivElement>('.picture');
    const previousPicture = picture.previousElementSibling;
    if (!previousPicture) return;

    picture.parentNode.insertBefore(picture, previousPicture);
  }

  public moveRight(event: MouseEvent) {
    const eventTarget = event.target as HTMLElement;
    const picture = eventTarget.closest<HTMLDivElement>('.picture');
    const nextPicture = picture.nextElementSibling;
    if (!nextPicture) return;

    insertAfter(nextPicture, picture);
  }

  public remove(event: MouseEvent) {
    const eventTarget = event.target as HTMLElement;
    eventTarget.closest('.picture').remove();
  }
}
