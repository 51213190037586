import { Turbo } from '@hotwired/turbo-rails';

export const loadCartContent = () => {
  const params = new URLSearchParams(window.location.search);
  fetch(`/v2/order/items?${params}`, {
    headers: {
      Accept: 'text/vnd.turbo-stream.html',
    },
  })
    .then((r) => r.text())
    .then((html) => {
      Turbo.renderStreamMessage(html);
      handleNewItemsParam();
    });
};

const handleNewItemsParam = () => {
  const params = new URLSearchParams(window.location.search);
  if (!params.has('new_items[]')) return;

  params.delete('new_items[]');
  history.replaceState(history.state, '', `${location.pathname}?${params}`);
};
