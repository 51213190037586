import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['configurationsPerson'];

  public declare readonly configurationsPersonTarget: HTMLDivElement;

  public unselect() {
    const person = $(this.configurationsPersonTarget);

    if (person) {
      const ids = person.attr('id').split('-');
      const personId = ids[0];
      const msisdnId = ids[1];

      const cache: string[] = JSON.parse(sessionStorage.getItem('bulk_order_recipients[]'));

      const newCache = cache
        .filter((entry) => {
          const el = JSON.parse(entry);
          return !(el.person_id === personId && el.msisdn_id === msisdnId);
        })
        .map((entry) => entry.replaceAll('"', '\\"').replaceAll('{', '"{').replaceAll('}', '}"'));

      sessionStorage.setItem('bulk_order_recipients[]', `[${newCache}]`);
    }
  }
}
