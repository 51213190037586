import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static values = { key: String };

  public declare readonly keyValue: string;

  public connect() {
    sessionStorage.removeItem(this.keyValue);
  }
}
