import { StreamActions, StreamElement } from '@hotwired/turbo';

StreamActions.removeDomElement = function (this: StreamElement) {
  const targetSelector = this.getAttribute('target');
  if (!targetSelector) return;

  const target = document.querySelector(targetSelector);
  if (!target) return;

  target.remove();
};
