import { CnCheckbox } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['setUsernameCheckbox'];

  public declare readonly setUsernameCheckboxTarget: CnCheckbox;

  public setUsernameChange() {
    const inputElement = this.element.querySelector<HTMLInputElement>(
      'input[data-address-tile="address_suffix_three"]',
    );
    if (!inputElement) return;

    inputElement.disabled = this.setUsernameCheckboxTarget.checked;
  }
}
