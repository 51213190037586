import { CnSelect } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';

export class HsbAccessoriesController extends Controller {
  public static targets = ['autocompleteController', 'typeSelect'];

  public declare readonly autocompleteControllerTarget: HTMLDivElement;
  public declare readonly typeSelectTarget: CnSelect;

  private urls = {
    mobile: '/super_admin/accessory_types',
    computer: '/super_admin/it_accessory_types',
  };

  public connect() {
    this.setAutocompleteUrl(this.typeSelectTarget.value[0]);

    this.typeSelectTarget.addEventListener('change', () => {
      this.setAutocompleteUrl(this.typeSelectTarget.value[0]);
    });
  }

  private setAutocompleteUrl(type: string) {
    this.autocompleteControllerTarget.setAttribute('data-autocomplete-url-value', this.urls[type]);
  }
}
