import { CnTable, CnTile } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';
import csrfToken from '../../csrf_meta_token';
import { insertAfter } from '../../insert';

export default class extends Controller {
  public static targets = ['toggleSortingButton', 'saveButton', 'table'];

  public declare readonly toggleSortingButtonTarget: CnTile;
  public declare readonly saveButtonTarget: CnTile;
  public declare readonly tableTarget: CnTable;

  private sortingEnabled = false;
  private defaultColumns = ['insight_name', 'name', 'edit', 'delete'];
  private sortingColumns = ['insight_name', 'name', 'sort_up', 'sort_down'];

  public connect() {
    this.toggleSortingButtonTarget.addEventListener('click', this.toggleSorting.bind(this));
  }

  public disconnect() {
    localStorage.removeItem('shownColumns[/super_admin/company_person_attributes]');
  }

  public toggleSorting() {
    this.sortingEnabled = !this.sortingEnabled;
    this.toggleSortingButtonTarget.querySelector('h1').textContent = this.sortingEnabled
      ? 'Abbrechen'
      : 'Neu sortieren';

    this.saveButtonTarget.classList.toggle('d-none', !this.sortingEnabled);

    this.tableTarget.querySelector('cn-table-filter').shownColumns = this.sortingEnabled
      ? this.sortingColumns
      : this.defaultColumns;
  }

  public sortUp(event: MouseEvent) {
    if (!this.sortingEnabled) return;

    const eventTarget = event.target as HTMLElement;
    const targetRow = eventTarget.closest('cn-table-row');
    const previousRow = targetRow.previousSibling;

    this.tableTarget.insertBefore(targetRow, previousRow);
  }

  public sortDown(event: MouseEvent) {
    if (!this.sortingEnabled) return;

    const eventTarget = event.target as HTMLElement;
    const targetRow = eventTarget.closest('cn-table-row');
    const previousRow = targetRow.nextSibling;

    insertAfter(previousRow, targetRow);
  }

  public save() {
    this.tableTarget.rows.forEach((row, index) => {
      if (row.matches('cn-table-header')) return;

      this.updateAttribute(row.getAttribute('data-person-attribute-id'), index);
    });

    this.toggleSorting();
  }

  private updateAttribute(id: string, sortIndex: number) {
    fetch(`${location.href}/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        company_person_attribute: { sort_index: sortIndex },
        authenticity_token: csrfToken,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
}
