import { Controller } from '@hotwired/stimulus';
import { escapeRegex } from '../regex';

export default class extends Controller {
  public static targets = ['formField', 'valueText', 'floatingLabel', 'options'];
  public static values = { default: String };

  public declare readonly formFieldTarget: HTMLInputElement;
  public declare readonly valueTextTarget: HTMLSpanElement;
  public declare readonly floatingLabelTarget: HTMLSpanElement;
  public declare readonly optionsTarget: HTMLUListElement;

  public declare readonly defaultValue: string;

  public connect() {
    const optionsRoot = $(this.optionsTarget);
    const selectedOption = optionsRoot.find(`li[value="${this.defaultValue.replace(/"/g, '\\"')}"]`);

    if (selectedOption.length > 0) {
      this.updateValue(selectedOption.attr('value'));
      this.updateLabel(selectedOption.data().label);
    }
  }

  public select(event: PointerEvent) {
    const eventTarget = $(event.target);
    this.updateValue(eventTarget.attr('value'));

    this.updateLabel((event.target as HTMLElement).dataset.label);
  }

  public search(event: InputEvent) {
    const eventTarget = $(event.target);
    $(this.optionsTarget)
      .children()
      .each(function () {
        const option = $(this);
        if (option.hasClass('cn-select-option')) {
          const regex = new RegExp(`.*${escapeRegex(eventTarget.val().toString().toLowerCase())}.*`);

          if (!regex.test(option.text().toLowerCase())) {
            option.css('display', 'none');
          } else {
            option.css('display', 'block');
          }
        }
      });
  }

  private updateValue(value: string) {
    this.formFieldTarget.value = value;
    $(this.formFieldTarget).trigger('change');
  }

  private updateLabel(label: string) {
    this.valueTextTarget.innerHTML = label;
    this.valueTextTarget.classList.remove('placeholder');
    this.floatingLabelTarget.classList.remove('hidden');
  }
}
