import { CnCheckbox } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public static targets = ['agree', 'remember', 'companyRemember', 'email', 'phone'];

  public declare readonly agreeTarget: CnCheckbox;
  public declare readonly rememberTarget: CnCheckbox;
  public declare readonly companyRememberTarget: CnCheckbox;
  public declare readonly emailTarget: HTMLInputElement;
  public declare readonly phoneTarget: HTMLInputElement;

  public connect() {
    this.updateStatus();
  }

  public updateStatus() {
    const enable = this.agreeTarget.checked;

    this.companyRememberTarget.disabled = !enable;
    this.rememberTarget.disabled = !enable;
    this.emailTarget.disabled = !enable;
    this.phoneTarget.disabled = !enable;
  }
}
