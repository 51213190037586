import { CnTableRow } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

interface TradeIn {
  id: string;
  device_name: { prefix: string; name: string };
  known: 'true' | 'false';
}

export default class extends Controller<HTMLFormElement> {
  public static targets = ['input', 'known'];

  public declare readonly inputTarget: HTMLInputElement;
  public declare readonly knownTarget: HTMLInputElement;

  private tradeIns: Map<string, TradeIn> = new Map<string, TradeIn>();
  private get itemId(): string {
    const params = new URLSearchParams(window.location.search);
    return params.get('item_id');
  }

  public connect() {
    $(this.inputTarget)
      .autocomplete({
        autoFocus: true,
        minLength: 0,
        source: (_, response) => {
          const term = this.inputTarget.value;
          $.get(`/v2/order/trade_ins/available_trade_ins?item_id=${this.itemId}&term=${term}`, (data: TradeIn[]) => {
            this.tradeIns.clear();
            data.forEach((tradeIn) => this.tradeIns.set(tradeIn.id, tradeIn));

            response(
              data.map((tradeIn) => ({
                label: `${tradeIn.device_name.prefix} - ${tradeIn.device_name.name}`,
                value: tradeIn.id,
              })),
            );
          });
        },
        select: (event, ui) => {
          event.preventDefault();

          const tradeIn = this.tradeIns.get(ui.item.value);
          this.knownTarget.value = tradeIn.known;
          this.inputTarget.value = tradeIn.device_name.name;
          this.requestTradeInDetails(tradeIn.id, tradeIn.known);
        },
      })
      .on('focus', function () {
        $(this).data('uiAutocomplete').search();
      });
  }

  public rowClicked(event: MouseEvent) {
    const eventTarget = event.target as CnTableRow;
    const tableRow = eventTarget.tagName === 'CN-TABLE-ROW' ? eventTarget : eventTarget.closest('cn-table-row');

    this.addGradeHiddenField(tableRow.getAttribute('grade'));
    this.element.submit();
  }

  private requestTradeInDetails(id: string, known: 'true' | 'false') {
    fetch(`/v2/order/trade_ins/details?id=${id}&known=${known}&item_id=${this.itemId}`, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  private addGradeHiddenField(value: string) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'v2_order_trade_in[expected_grade]';
    input.value = value;
    this.element.append(input);
  }
}
